import React from 'react'
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import Explorer from "../components/explorer"
import HeroTitleBlock from "../components/hero-title-block"
import DiscoverMore from "../components/discover-more"
import ModifiedHelmet from '../components/modified-helmet'

export default ({ data: { allDatoCmsCategory, datoCmsUniversalTag: {id, title, landingPageHeader, landingPageDiscoverMore, seoMetaTags} } }) => {
  let explorer = {};
  explorer.tabs = [
    {title: `All ${title}`, visibleItems: 12, list: { __typename: "DatoCmsDynamicList", scopes: [{__typename: "DatoCmsUniversalTag", id: id }] }},
    {title: "Filter category", list: { __typename: "FilterDropdown",  filters: allDatoCmsCategory.edges.map(edge => edge.node), scopes: [{__typename: "DatoCmsUniversalTag", id: id }] } }
  ]
  return (
    <Layout>
      <ModifiedHelmet seo={seoMetaTags} />
      {landingPageHeader && (
        <HeroTitleBlock widget={landingPageHeader} />
      )}
      {landingPageDiscoverMore && (
        <DiscoverMore widget={landingPageDiscoverMore} />
      )}
      <Explorer widget={explorer} />
    </Layout>
  )
}

export const query = graphql`
  query UniversalTagQuery($id: String!) {
    datoCmsUniversalTag(id: { eq: $id }) {
      id
      title
      landingPageHeader {
        ...HeroTitleBlock
      }
      landingPageDiscoverMore {
        ...DiscoverMore
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    allDatoCmsCategory {
      edges {
        node {
          __typename
          id
          title
          slug
        }
      }
    }
  }
`
